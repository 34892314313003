<!-- =========================================================================================
  File Name: .vue
  Description: 
========================================================================================== -->

<template>
  <div id="appointment-view-page">
    <!-- br />
    <div class="vx-row">
      <div class="vx-col lg:w-1/1 w-full">
        <vx-card :title="service_data.name">
          <div>
            <p>USD {{ service_data.priceMaleTotal }}</p>
            <p>{{ service_data.type }}</p>
        
            <p class="my-4">{{currentDate}} - packID: {{selectedPackgId}} - dateNoSelected: {{dateNoSelected}}</p>
          </div>
        </vx-card>
      </div>
    </div -->
        <br />
<vue-apex-charts type="heatmap" height="350" :options="chartOptions" :series="resourcePatternSeriesData"></vue-apex-charts>
    <br />
    <br />
<vue-apex-charts type="heatmap" height="350" :options="heatMapChartPersonnel.chartOptions" :series="heatMapChartPersonnel.series"></vue-apex-charts>
    <br />
        <br />
<vue-apex-charts type="heatmap" height="350" :options="heatMapChartEquipment.chartOptions" :series="heatMapChartEquipment.series"></vue-apex-charts>
    <br />
    <div class="vx-row">
      <div class="vx-col lg:w-1/1 w-full">
        <vx-card>
          <div>
            <h1 align="center">Availability for {{ formatDateFromDayNo(181) }}</h1>
            <h5 align="center">Pick a day and Select your slot</h5>
          </div>
        </vx-card>
      </div>
    </div>
    <br />

    <vs-row >
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
        <vx-card class="text-center cursor-pointer">
          <div>
            <h2 class="mb-2"><u><b>AM</b></u></h2>
          </div>
        </vx-card>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
        <vx-card class="text-center cursor-pointer">
          <div>
            <h2 class="mb-2"><u><b>PM</b></u></h2>
          </div>
        </vx-card>
      </vs-col>      
    </vs-row>
    <br/>
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="center" vs-w="12">
      <vs-col vs-type="flex" vs-justify="center" vs-align="top" vs-w="5">
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/3 sm:w-1/3 mb-base" v-for="(slot,index) in resourcePatternData.personnel" v-bind:key="index" @click="$router.push(slot.url).catch(() => {})">
        
            <b>{{index}}</b>
          
          </div>
        </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
          
          </vs-col>      
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="5">
        <div class="vx-row">
          <div class="vx-col w-full md:w-1/3 sm:w-1/3 mb-base" v-for="(slot,index2) in resourcePatternData.space" v-bind:key="index2"  @click="$router.push(slot.url).catch(() => {})">
              <b>{{index2}}</b>
          </div>
        </div>
      </vs-col>      
    </vs-row>


    <br /> <!-- pre>{{ packAvalibilityInfo }}</pre> <br /> <pre>{{ availability_constraints }}</pre -->

        <vx-card>

      <!-- TABLE ACTION ROW -->
      <div class="flex flex-wrap justify-between items-center">

        <!-- ITEMS PER PAGE -->
        <div class="mb-4 md:mb-0 mr-4 ag-grid-table-actions-left">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ contacts.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : contacts.length }} of {{ contacts.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(100)">
                <span>100</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(150)">
                <span>150</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
        <div class="flex flex-wrap items-center justify-between ag-grid-table-actions-right">
          <vs-input class="mb-4 md:mb-0 mr-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button>
        </div>
      </div>
      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="contacts"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage" />

    </vx-card>
  </div>
</template>

<script>
//import axios from "@/axios.js";
import Prism from "vue-prism-component";
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import Datepicker from 'vuejs-datepicker'
import { AgGridVue } from 'ag-grid-vue'
import contacts from './data.json'
import VueApexCharts from 'vue-apexcharts'

import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
//import DatepickerInline from './DatepickerInline.vue'
//import DatetimePickerInline from './DatetimePickerInline.vue'
//import DatepickerHighlightDates from './DatepickerHighlightDates.vue'
import 'lodash/lodash.js'
import _ from 'lodash';    
import moduleCarePlan from '@/store/careplan/moduleCarePlan.js'

//const themeColors = ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E']

function generateDataHeatMap (count, yrange) {
  let i = 0
  const series = []
  while (i < count) {
    const x = `${(i + 1).toString()}`
    const y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min

    series.push({
      x,
      y
    })
   // console.log("START series");
   // console.log(series);
   // console.log("END series");
    i++
  }
  return series
}


export default {
  components: {
    Prism, flatPickr,
    Datepicker,
     AgGridVue,
     VueApexCharts
    //DatepickerInline, DatetimePickerInline,
    //DatepickerHighlightDates
  },
  data() {
   
    return {
      date:"",
       searchQuery: '',
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'First Name',
          field: 'resourceType',
          width: 175,
          filter: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          headerCheckboxSelection: true
        },
        {
          headerName: 'Last Name',
          field: 'resourceId',
          filter: true,
          width: 175
        },
        {
          headerName: 'Email',
          field: 'pattern',
          filter: true,
          width: 250,
          pinned: 'left'
        }
      ],
      contacts,
      themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
      chartOptions: {
        colors:  ["#008FFB"],
        dataLabels: {
          enabled: true,
             style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ["#000000"]
              }
          },
         // colors: ["#008FFB"],
        },
      heatMapChartPersonnel: {
        series: [{
            name: 'Resource 1',
            data: [{x:"1",y:2},{x:"2",y:3},{x:"3",y:1},{x:"4",y:0},{x:"5",y:1},{x:"6",y:3},{x:"7",y:2}]
          },
          {
            name: 'Resource 2',
            data: generateDataHeatMap(25, {
              min: 0,
              max: 9
            })
          },
          {
            name: 'Metric3',
            data: generateDataHeatMap(25, {
              min: 0,
              max: 9
            })
          }
          ],
        chartOptions: {
          colors:  ["#008FFB"],
          dataLabels: {
            enabled: true,
            style: {
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ["#000000"]
            }
          },
         // colors: ["#008FFB"],
        }
      },
      heatMapChartEquipment: {
        series: [{
            name: 'Equipment 1',
            data: generateDataHeatMap(25, {
              min: 0,
              max: 9
            })
          },
          {
            name: 'Equipment 2',
            data: generateDataHeatMap(25, {
              min: 0,
              max: 9
            })
          }
          ],
        chartOptions: {
          colors:  ["#FF9F43"],
          dataLabels: {
            enabled: false
          },
         // colors: ["#008FFB"],
        }
      }

    };
  },
    watch: {
    '$store.state.windowWidth' (val) {
      if (val <= 576) {
        this.maxPageNumbers = 4
        this.gridOptions.columnApi.setColumnPinned('email', null)
      } else this.gridOptions.columnApi.setColumnPinned('email', 'left')
    }
  },
  computed: {
   resourcePatternData() {
     console.log(this.$store.state.moduleCarePlan.resourcePattern);

      const property = "resourceType";
      const groupResourceType = _.groupBy(this.$store.state.moduleCarePlan.resourcePattern, property);
      var resourcePatternByResourceType = groupResourceType;
      console.log(resourcePatternByResourceType);


      return resourcePatternByResourceType;
    },
       resourcePatternSeriesData() {
     console.log(this.$store.state.moduleCarePlan.heatMapChart);


      return this.$store.state.moduleCarePlan.heatMapChart;
    },
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 50
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    formatDateFromDayNoMMDD(a) {
      const _this = this
      return _this.$moment(a, "DDD").format("DD");
    },
    formatDateFromDayToDayName(a) {
      const _this = this
      return _this.$moment(a, "DDD").format("dddd");
    },
    formatDateFromDayNo(a) {
      const _this = this
      return _this.$moment(a, "DDD").format("MMMM");
    },
    //updateAvalability(packgId, sdayNo, slotNo, availabilityNb) {},
    formSubmitted() {
      alert("Form submitted!" + this.$route.params.pkg_id);
    },
    submitForm() {
      console.log(this.$data);
    },
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created() {


    
    console.log(new Date(2020, 4, 16));
    //var dateNoSelected = _this.$moment(this.todayDate).format('MM/DD/YYYY hh:mm');
    //this.currentDate = parseInt(_this.$moment(todayDate).format("DDD"));//todayNo;//136
    //var fullDateToArray =  this.fullDateToArray = _this.$moment(this.currentDate, "DDD").toArray();
console.log("this.contacts");
console.log(this.contacts);
console.log("this.contacts");

    if (!moduleCarePlan.isRegistered) {
      this.$store.registerModule('moduleCarePlan', moduleCarePlan)
      moduleCarePlan.isRegistered = true
    }

     const payload = {
        intervalInHours: 0.1,
        packgId: this.$route.params.resourceId
      }
      console.log("START fetchResourcePattern payload");
       console.log(payload);     
      console.log("END fetchResourcePattern payload");



    this.$store.dispatch("moduleCarePlan/fetchResourcePattern", payload).catch(err => { console.error(err) }); 

  //this.heatMapChartPersonnel.series.name = [];
  //this.heatMapChartPersonnel.series.data = [];
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";
